import React, { PureComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import Select from 'react-select';

import isEqual from 'react-fast-compare';
import { evalShowWhen } from '../../../helpers/helperIndex';

import { formSelectUpdated, clearRequiredField } from '../../../redux/actions/actionsIndex';

import {
  makeGetInputStateByField,
  makeGetInputStateByFilterWhen,
} from '../../../redux/selectors/selectorIndex';
import { FormContext } from '../context/contextIndex';
import { SelectContainer, SelectWrapper } from '../atomicStyledComponents/stylesIndex';

const memoEvalShowWhen = memoize(evalShowWhen);

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.key = uuidv4();
  }

  componentDidUpdate(prevProps, prevState) {
    return null;
  }

  handleSelectChange = (e) => {
    
    const { value } = e;
    // Converted because <option> cannot handle null values out of the box
    const convertedValue = value === '' ? null : value;
    let valuePrev;
    let action;
    if (this.props.optValue) {
      if (!value) {
        action = 'delete';
        valuePrev = this.props.optValue;
      } else {
        valuePrev = this.props.optValue;
        action = 'change';
      }
    } else if (!this.props.optValue) {
      valuePrev = null;
      action = 'add';
    }
    this.props.handleSelect(
      this.props.field,
      convertedValue,
      valuePrev,
      action,
      this.props.parent,
      this.props.prenuvoID,
    );
  };

  render() {
    const options = [{"label": "Select...", "value": ''}].concat(this.props.options);
    return (
      <SelectContainer key={this.key} context={this.props.context}>
        <SelectWrapper
          className="dropdown"
          context={this.props.formContext}
          disabled={this.props.disabled}
          value={this.props.optValue ? true : false}
        >
          <Select
            disabled={this.props.disabled}
            name={`${this.props.field}-Select`}
            id={`${this.props.field}-Select`}
            options={options}
            className={`${this.props.field.split('.').join(' ')} dropdown basic-multi-select`}
            classNamePrefix="select"
            formContext={this.props.formContext}
            menuPlacement="bottom"
            menuPortalTarget={document.body}
            isSearchable={this.props?.data?.config?.searchable ? this.props.data.config.searchable : false}
            styles={{
              option: (base, { data }) => ({
                ...base,
                borderBottom: `1px solid #B1B1B1`,
                backgroundColor: data.color,
                color: data.value ? '#000000' : '#adb5bd',
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
            defaultValue={this.props.optValue === null || this.props.disabled ? '' : this.props.options.find((opt) => opt.value === this.props.optValue)}
            onChange={this.handleSelectChange}
          />
        </SelectWrapper>
      </SelectContainer>
    );
  }
}

Dropdown.contextType = FormContext;

const mapDispatchToProps = {
  handleSelect: formSelectUpdated,
  clearReq: clearRequiredField,
};

const makeMapStateToProps = (_) => {
  const getSelect = makeGetInputStateByField();
  const getInputStateByFilterWhen = makeGetInputStateByFilterWhen();

  return (state, props) => {
    const select = getSelect(state, props);
    let newProps = {};

    if (select && select.length > 0) {
      if (props.optValue !== select[0].value) {
        newProps = {
          ...newProps,
          optValue: select[0].value,
        };
      }
    }

    // Default values for selects
    if (
      !props.optValue &&
      props.defaults &&
      props.defaults.length > 0 &&
      (!props.checked || props.checked.length < 1)
    ) {
      for (const def of props.defaults) {
        // TRIAGE-1661: Added feature of disabling defaults within the JSON (using "disabled": true)
        //  which prevents defaults to auto-fill on frontend
        if (def.disabled === undefined || def.disabled === false) {
          // const inputs = makeGetFieldFromState(def.defaultWhen.field)(state);
          const defaultWhens = getInputStateByFilterWhen(state, def.defaultWhen);
          const defaulted = memoEvalShowWhen(def.defaultWhen, defaultWhens);

          if (defaulted) {
            // const defaultOption = props.options.filter((opt) => opt.value === def.default);

            newProps = {
              ...newProps,
              optValue: def.default,
            };
          }
        }
      }
    }

    return isEqual(newProps, props) ? props : newProps;
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Dropdown);
